import { datadogRum } from '@datadog/browser-rum';
import { NetworkStatus, MutationFunctionOptions, FetchResult } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  JobsiteWorkerOrientationStatus,
  MutationUploadSingleFileArgs,
  UserIdentity,
  Worker,
  UploadSingleFileMutationHookResult,
} from 'apollo/generated/client-operations';
import imageCompression from 'browser-image-compression';
import { Location } from 'history';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { Entries, ServerFile, StringEnum } from 'types';
import { FILE_CHANGED_ERROR_MESSAGE } from './validation';

export { useDebouncedValue } from './useDebouncedValue';
export { useIsMounted } from './useIsMounted';
export { useUpdatableState } from './useUpdatableState';
export { useQueryOrderBy } from './useQueryOrderBy';
export { usePrevious } from './usePrevious';
export { useBoolean } from './useBoolean';
export { useResettableState } from './useResettableState';
export { useModalState } from './useModalState';
export { useArrowNavigation } from './useArrowNavigation';
export { useInterval } from './useInterval';
export { useDeferredFormSubmission } from './useDeferredFormSubmission';

export { DeferredPromise } from './deferredPromise';

export function evalJsCode<TReturn = unknown>(
  jsExpression: string,
  options?: Record<string, unknown> & {
    type?: 'expression' | 'code';
    ctx?: { dependencies?: Record<string, unknown> };
  },
): TReturn {
  const { type = 'expression', ...restOptions } = options ?? {};
  const ctxArgs = restOptions ? Object.keys(restOptions) : [];
  const ctxValues = restOptions ? Object.values(restOptions) : [];
  const depArgs = restOptions?.ctx?.dependencies ? Object.keys(restOptions.ctx.dependencies) : [];
  const depValues = restOptions?.ctx?.dependencies ? Object.values(restOptions.ctx.dependencies) : [];

  const jsCode = type === 'code' ? `${jsExpression};` : `return (${jsExpression});`;
  // eslint-disable-next-line no-new-func
  return Function(...depArgs, ...ctxArgs, `"use strict"; ${jsCode};`)(...depValues, ...ctxValues);
}

export const captureError = (error: string | Error, context?: Record<string, any>): void => {
  if (typeof error === 'string') {
    datadogRum.addError(new Error(error), context);
  } else {
    datadogRum.addError(error, context);
  }
};

export const objectGet = <T = any>(path: any, object: any, fallback: any = null): T => {
  return path.reduce((xs: any, x: any) => {
    return xs != null && xs[x] != null ? xs[x] : fallback;
  }, object);
};

export const getFullNameForUser = (user: any): string => {
  if (!user) return '';
  return `${objectGet(['identity', 'firstName'], user, '')} ${objectGet(['identity', 'lastName'], user, '')}`;
};

// TODO make better placeholder then ??
export const getInitialsForUser = (user: any): string => {
  if (!user) return '??';
  const firstName = objectGet(['identity', 'firstName'], user, '0');
  const lastName = objectGet(['identity', 'lastName'], user, '0');
  return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`.toUpperCase();
};

export const getInitialsForCompactUser = (user: { fullName?: string }): string => {
  if (!user?.fullName) return '??';
  const [firstName, lastName] = user.fullName.split(' ');
  return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`.toUpperCase();
};

export const getUserFullName = (user: { identity?: Pick<UserIdentity, 'firstName' | 'lastName'> }): string => {
  if (user == null) {
    return '';
  }
  return `${user?.identity?.firstName} ${user?.identity?.lastName}`;
};

export const momentFormatter = 'MM/DD/YYYY';
export const momentISODateFormatter = 'YYYY-MM-DD';

export const getFormattedDate = (date: any, formatter: string = momentFormatter): string => {
  if (!date) {
    return '';
  }
  return moment(date).format(formatter);
};

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber != null) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
    if (parsedNumber) {
      return parsedNumber.formatNational();
    }
  }
  return phoneNumber;
};

export const getPhoneNumberAsE164 = (phoneNumber: string): string => {
  if (phoneNumber != null) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
    if (parsedNumber) {
      return parsedNumber.number?.toString();
    }
  }
  return phoneNumber;
};

type WorkerForFullName = Pick<Worker, 'middleInitial' | 'suffix'> & {
  user?: {
    identity?: Pick<Worker['user']['identity'], 'firstName' | 'lastName'>;
  };
};

export const getWorkerFullName = (worker: WorkerForFullName): string => {
  const firstName = worker.user?.identity?.firstName || '';
  const middleInitial = worker?.middleInitial || '';
  const lastName = worker?.user?.identity?.lastName || '';
  const suffix = worker?.suffix || '';

  return [firstName, middleInitial, lastName, suffix].filter((value) => value).join(' ');
};

export const formatTimeForUrl = (time: string): string => time?.replace(/[: ]/g, '');

const parseTimeFromUrl = (text: string | string[]): string => {
  if (typeof text !== 'string') {
    return '';
  }
  if (!text || text.length < 6) {
    return text;
  }
  if (text.length === 6) {
    return `${text.substring(0, 2)}:${text.substring(2, 4)} ${text.substring(4, 6)}`;
  }
  return `${text.substring(0, 2)}:${text.substring(2, 4)}:${text.substring(4, 10)} ${text.substring(10)}`;
};

const URL_ARRAY_SEPARATOR = '~';
export const formatArrayForUrl = <T extends string | number>(array: T[]): string => array?.join(URL_ARRAY_SEPARATOR);

const parseStringArrayFromUrl = (text: string | string[] | null): string[] | null => {
  if (!text) {
    return null;
  }
  if (typeof text === 'string') {
    return text.split(URL_ARRAY_SEPARATOR);
  }
  return text;
};

const parseNumberFromUrl = (text: string | string[] | null): number | null => {
  return text && typeof text === 'string' ? Number(text) : null;
};

const parseMomentFromUrl = (text: string | string[] | null): moment.Moment | null => {
  return text && typeof text === 'string' ? moment.utc(text) : null;
};

const parseStringFromUrl = (text: string | string[] | null, defaultValue: string | null = ''): string | null => {
  return text && typeof text === 'string' ? text : defaultValue;
};

const parseBooleanFromUrl = (text: string | string[] | null): boolean => {
  return text === 'true';
};

export interface PageSearchParams {
  page?: number;
  secondaryPage?: number;
  offset?: number;
  limit?: number;
  query?: string;
  date?: Moment;
  startTime?: string;
  endTime?: string;
  startDate?: Moment;
  endDate?: Moment;
  jobsite?: string;
  jobsiteId?: string;
  jobsiteIds?: string[];
  contractorId?: string;
  contractorIds?: string[];
  developerId?: string;
  developerIds?: string[];
  gatewayIds?: string[];
  createdByIds?: string[];
  roleKey?: string;
  roleKeys?: string[];
  search?: string;
  orderBy?: string;
  orderByDesc?: boolean;
  siteAccess?: string;
  eventStatus?: string;
  rejectionReasons?: string[];
  locationStatuses?: string[];
  onboardingStatus?: string;
  siteSafetyOrientationStatus?: string;
  announcementType?: string;
  announcementStatus?: string;
  cities?: string[];
  states?: string[];
  hasAttachments?: boolean;

  // worker-reports params
  realtime?: boolean;
  currentlyOnsite?: string;
  compliant?: string;
  overnight?: string;
  contractor?: string;
  trade?: string;
  trades?: string[];
  tradeClass?: string;
  tradeClasses?: string[];
}

export type FlattenStringArrays<T> = {
  [P in keyof T]: T[P] extends Array<unknown> ? string : T[P] extends Moment ? string : T[P];
};

export type PageUrlUpdateParams = FlattenStringArrays<PageSearchParams>;

export const parsePageQueryParams = (location: Location): PageSearchParams => {
  const {
    page,
    secondaryPage,
    offset,
    limit,
    query,
    date,
    startTime,
    endTime,
    startDate,
    endDate,
    jobsite,
    jobsiteId,
    jobsiteIds,
    contractorId,
    contractorIds,
    developerId,
    developerIds,
    gatewayIds,
    createdByIds,
    roleKey,
    roleKeys,
    search,
    orderBy,
    orderByDesc,
    siteAccess,
    eventStatus,
    rejectionReasons,
    locationStatuses,
    onboardingStatus,
    siteSafetyOrientationStatus,
    cities,
    states,
    hasAttachments,

    // worker-reports params
    realtime,
    currentlyOnsite,
    compliant,
    overnight,
    contractor,
    trade,
    trades,
    tradeClass,
    tradeClasses,

    // announcement params
    announcementType,
    announcementStatus,
  } = queryString.parse(location.search);

  return {
    page: page && typeof page === 'string' ? Number(page) - 1 : 0,
    secondaryPage: secondaryPage && typeof secondaryPage === 'string' ? Number(secondaryPage) - 1 : 0,
    offset: parseNumberFromUrl(offset),
    limit: parseNumberFromUrl(limit),
    query: parseStringFromUrl(query, null),
    date: parseMomentFromUrl(date),
    startTime: parseTimeFromUrl(startTime),
    endTime: parseTimeFromUrl(endTime),
    startDate: parseMomentFromUrl(startDate),
    endDate: parseMomentFromUrl(endDate),
    jobsite: parseStringFromUrl(jobsite),
    jobsiteId: parseStringFromUrl(jobsiteId, null),
    jobsiteIds: parseStringArrayFromUrl(jobsiteIds),
    developerId: parseStringFromUrl(developerId, null),
    developerIds: parseStringArrayFromUrl(developerIds),
    gatewayIds: parseStringArrayFromUrl(gatewayIds),
    createdByIds: parseStringArrayFromUrl(createdByIds),
    contractorId: parseStringFromUrl(contractorId, null),
    contractorIds: parseStringArrayFromUrl(contractorIds),
    roleKey: parseStringFromUrl(roleKey, null),
    roleKeys: parseStringArrayFromUrl(roleKeys),
    search: parseStringFromUrl(search),
    orderBy: parseStringFromUrl(orderBy),
    orderByDesc: parseBooleanFromUrl(orderByDesc),
    siteAccess: parseStringFromUrl(siteAccess),
    eventStatus: parseStringFromUrl(eventStatus, null),
    rejectionReasons: parseStringArrayFromUrl(rejectionReasons),
    locationStatuses: parseStringArrayFromUrl(locationStatuses),
    onboardingStatus: parseStringFromUrl(onboardingStatus, null),
    siteSafetyOrientationStatus: parseStringFromUrl(siteSafetyOrientationStatus, null),
    cities: parseStringArrayFromUrl(cities),
    states: parseStringArrayFromUrl(states),
    hasAttachments: parseBooleanFromUrl(hasAttachments),

    // worker-reports params
    realtime: parseBooleanFromUrl(realtime),
    currentlyOnsite: parseStringFromUrl(currentlyOnsite),
    compliant: parseStringFromUrl(compliant, null),
    overnight: parseStringFromUrl(overnight, null),
    contractor: parseStringFromUrl(contractor),
    trade: parseStringFromUrl(trade, null),
    trades: parseStringArrayFromUrl(trades),
    tradeClass: parseStringFromUrl(tradeClass, null),
    tradeClasses: parseStringArrayFromUrl(tradeClasses),

    // announcement params
    announcementStatus: parseStringFromUrl(announcementStatus, null),
    announcementType: parseStringFromUrl(announcementType, null),
  };
};

export const getWorkerOrientationStatus = (currentOrientationStatus: JobsiteWorkerOrientationStatus): string => {
  if (!currentOrientationStatus || currentOrientationStatus === JobsiteWorkerOrientationStatus.NotStarted) {
    return 'Not Started';
  }
  if (currentOrientationStatus === JobsiteWorkerOrientationStatus.InProgress) {
    return 'In Progress';
  }
  return 'Complete';
};

export const getWorkerOrientationStatusBadge = (currentOrientationStatus: JobsiteWorkerOrientationStatus): string => {
  if (!currentOrientationStatus || currentOrientationStatus === JobsiteWorkerOrientationStatus.NotStarted) {
    return 'badge badge-soft-secondary';
  }
  if (currentOrientationStatus === JobsiteWorkerOrientationStatus.InProgress) {
    return 'badge badge-soft-dark';
  }
  return 'badge badge-soft-success';
};

export const getCurrentISOFormattedDate = (): string => {
  return moment().format(momentISODateFormatter);
};

export const dataURLtoBlob = (dataURI: any): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const flatMap = (myArray: Array<any>, myCallback: any): any => {
  return (myArray || []).map(myCallback).reduce((acc: any, val: any) => acc.concat(val), []);
};

export const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  initialQuality: 0.7,
};

export const isServerFile = (file: File | Pick<ServerFile, 'fileId'>): file is Pick<ServerFile, 'fileId'> => {
  return Boolean(file && (file as ServerFile).fileId);
};

interface UploadFileResponse {
  uploadSingleFile: ServerFile;
}

export const tryFileCompression = async (originalFile: File): Promise<File | Blob> => {
  try {
    return await imageCompression(originalFile, imageCompressionOptions);
  } catch (error) {
    // no need to log this error
    // captureError(error as string | Error);
    return originalFile;
  }
};

export type UploadFileMutation =
  | ((
      options?: MutationFunctionOptions<UploadFileResponse, MutationUploadSingleFileArgs>,
    ) => Promise<FetchResult<UploadFileResponse>>)
  | UploadSingleFileMutationHookResult[0];

export const uploadCompressedFile = async (
  originalFile: File | { fileId: string },
  uploadFile: UploadFileMutation,
): Promise<string> => {
  if (isServerFile(originalFile)) {
    return originalFile?.fileId;
  }

  // compressedFile is actually a Blob object rather than a File
  const fileData = await tryFileCompression(originalFile);
  const file = await uploadFile({
    variables: {
      fileInput: {
        uploadData: fileData,
        isPublic: false,
      },
    },
  });
  return file?.data?.uploadSingleFile?.fileId;
};

export const getHashCode = (text: string): number => {
  let hash = 0;
  if (text.length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < text.length; i++) {
    const chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
    hash |= 0; // eslint-disable-line no-bitwise -- Convert to 32bit integer
  }
  return hash;
};

export const getFileHash = async (file: File): Promise<number> => {
  return new Promise<number>((resolve) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      const fileHash = getHashCode(reader.result as string);
      resolve(fileHash);
    };

    reader.readAsBinaryString(file);
  });
};

export const getFileContent = async (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      resolve(reader.result as string);
    };

    reader.readAsText(file);
  });
};

export const isValidFile = async (file: File): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      resolve(Boolean(reader.result));
    };
    reader.onerror = (): void => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const validateFiles = async (files: File[]): Promise<boolean | string> => {
  try {
    const localFiles = files.filter((file) => file instanceof File);
    const isValid = await Promise.all(localFiles.map((file) => isValidFile(file)));
    return isValid.every(Boolean) || FILE_CHANGED_ERROR_MESSAGE;
  } catch (error) {
    return FILE_CHANGED_ERROR_MESSAGE;
  }
};

export const uploadFile = async (
  { file, fileKey }: { fileKey: string; file: File },
  uploadFileMutation: (
    options?: MutationFunctionOptions<UploadFileResponse, MutationUploadSingleFileArgs>,
  ) => Promise<FetchResult<UploadFileResponse>>,
): Promise<{ fileKey: string; fileId: string }> => {
  const fileId = await uploadCompressedFile(file, uploadFileMutation);
  return { fileKey, fileId };
};

const getFileKey = (file: File): string => `${file.name}-${file.lastModified}`;

export const uploadDFilesDistinctly = async (
  files: { fieldName: string; file: File }[],
  uploadFileMutation: (
    options?: MutationFunctionOptions<UploadFileResponse, MutationUploadSingleFileArgs>,
  ) => Promise<FetchResult<UploadFileResponse>>,
): Promise<Record<string, { fieldName: string; fileKey: string; fileId: string }>> => {
  if (!files.length) return {};

  const filesWithDistinctKeys = files.map((f) => ({ ...f, fileKey: getFileKey(f.file) }));
  const distinctFiles = Object.fromEntries(filesWithDistinctKeys.map((f) => [f.fileKey, f]));

  const distinctFilesToUpload = Object.entries(distinctFiles).map(([fileKey, { file }]) =>
    uploadFile({ fileKey, file }, uploadFileMutation),
  );

  const uploadedFiles = await Promise.all(distinctFilesToUpload);
  return Object.fromEntries(
    filesWithDistinctKeys.map(({ fieldName, fileKey }) => [
      fieldName,
      {
        fieldName,
        fileKey,
        fileId: uploadedFiles.find((uf) => uf.fileKey === fileKey).fileId,
      },
    ]),
  );
};

export const getCurrentDomain = (): string => {
  if (typeof window !== 'undefined' && window) return window?.location?.origin;
  return '';
};

/** camelCase -> Title Case */
export const camelToTitleCase = (text: string): string => {
  if (!text) return text;
  const result = text.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').trimStart();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/** camelCase -> snake_case */
export const camelToSnakeCase = (text: string): string => {
  return text?.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`);
};

/** camelCase -> kebab-case */
export const camelToKebabCase = (text: string): string => {
  return text.replace(/([A-Z])/g, '-$1').toLowerCase();
};

/** Title Case -> kebab-case */
export const titleToKebabCase = (text: string): string => {
  return text
    ?.replace(/[ /]/g, '-')
    .replace(/([-])\1+/g, '$1') // replace consecutive dashes
    .toLowerCase();
};

/** Title Case -> snake_case */
export const titleToSnakeCase = (text: string): string => {
  return text
    ?.replace(/[ -/]/g, '_')
    .replace(/([_])\1+/g, '$1') // replace consecutive underscores
    .toLowerCase();
};

/** PascalCase -> kebab-case */
export const pascalToKebabCase = camelToKebabCase;

/** PascalCase -> snake_case */
export const pascalToSnakeCase = (text: string): string => {
  if (!text) return text;
  if (text.length === 1) return text.toLowerCase();
  return text.charAt(0).toLowerCase() + text.slice(1).replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`);
};

/** PascalCase -> Train-Case */
export const pascalToTrainCase = (text: string): string => {
  if (!text || text.length < 2) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).replace(/([A-Z])/g, '-$1');
};

/** kebab-case -> camelCase */
export const kebabToCamelCase = (text: string): string => {
  return text.replace(/-./g, (letters) => {
    return letters[1].toUpperCase();
  });
};

/** kebab-case -> Title Case */
export const kebabToTitleCase = (text: string): string => {
  if (!text) return text;
  const result = text.replace(/-./g, (letters) => ` ${letters[1].toUpperCase()}`);
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export function isStringEnumKey<T extends StringEnum>(enumSrc: T, key: unknown): key is keyof T {
  return enumSrc[key as keyof T] !== undefined;
}

export function isStringEnumValue<T extends StringEnum>(enumSrc: T, value: string): value is T[keyof T] {
  return Object.values(enumSrc).includes(value);
}

export function ensureStringEnumValue<T extends StringEnum>(enumSrc: T, value: string): T[keyof T] {
  return isStringEnumValue(enumSrc, value) ? value : null;
}

export type Enum = { [s: number]: string };

export function isEnumKey<T extends Enum>(enumSrc: T, key: unknown): key is keyof T {
  return Number.isInteger(enumSrc[key as keyof T]);
}

export const isPlainObject = (value: unknown): boolean => typeof value === 'object' && value?.constructor === Object;

const nullifyEmptyField = (value: unknown): unknown => {
  if (value == null || value === '') {
    return null;
  }
  if (Array.isArray(value)) {
    return value.map((item) => nullifyEmptyField(item));
  }
  if (isPlainObject(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([field, fieldValue]) => [field, nullifyEmptyField(fieldValue)]),
    );
  }
  return value;
};

export const nullifyEmptyFields = <T extends Record<string, unknown>>(jsObject: T): T => {
  return nullifyEmptyField(jsObject) as T;
  // return JSON.parse(JSON.stringify(jsObject), (_key, value) => (value === '' ? null : value));
};

export const stringifyEmptyFields = <T extends Record<string, unknown>>(jsObject: T): T => {
  const stringifiedObject = JSON.stringify(jsObject, (_key, value) => value ?? null);
  return JSON.parse(stringifiedObject, (_key, value) => (value === null ? '' : value));
};

export const ensureNonEmptyItems = <T>(items: T[]): T[] => items.filter(Boolean);

export const ensureDistinctItems = <T>(items: T[], byField?: keyof T): T[] => {
  if (byField) {
    return (
      items &&
      Array.from(
        items
          .filter((item) => item?.[byField])
          .reduce((map, item) => map.set(item[byField].toString(), item), new Map<string, T>())
          .values(),
      )
    );
  }
  return items && Array.from(items.reduce((set, item) => set.add(item), new Set<T>()));
};

const queryLoadingNetworkStatuses = [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch];
export const isQueryLoading = (networkStatus: NetworkStatus): boolean =>
  queryLoadingNetworkStatuses.includes(networkStatus);

export const isEmpty = <T>(value: T): boolean => {
  if (value == null) {
    return true;
  }
  if (Array.isArray(value)) {
    return !value.length;
  }
  if (isPlainObject(value)) {
    return !Object.keys(value).length;
  }
  return false;
};

export const isNotEmpty = <T>(value: T): boolean => !isEmpty(value);

const ensureNonUndefinedField = (value: unknown): unknown => {
  if (value == null) {
    return value;
  }
  if (Array.isArray(value)) {
    const cleanArray = value.map(ensureNonUndefinedField).filter((item) => item !== undefined);
    return isEmpty(cleanArray) ? undefined : cleanArray;
  }
  if (isPlainObject(value)) {
    const cleanObject = Object.fromEntries(
      Object.entries(value)
        .map(([field, fieldValue]) => [field, ensureNonUndefinedField(fieldValue)])
        .filter(([, fieldValue]) => fieldValue !== undefined),
    );
    return isEmpty(cleanObject) ? undefined : cleanObject;
  }
  return value;
};

export const ensureNonUndefinedFields = <T extends Record<string, unknown> | Array<unknown>>(jsObject: T): T => {
  return ensureNonUndefinedField(jsObject) as T;
};

export const objectEntries = <T>(obj: T): Entries<T> => Object.entries(obj) as Entries<T>;

export const emptyIf = <T = unknown>({
  value,
  valueTest,
  extraTest,
  emptyValue = '',
}: {
  value: T;
  valueTest: T;
  extraTest?: boolean;
  emptyValue?: T | string | null | undefined;
}): T | string => {
  return value === valueTest && (extraTest === undefined || extraTest) ? emptyValue : value;
};

export const isSsr = (): boolean => typeof window === 'undefined';

export const isIphone = (): boolean => {
  if (isSsr()) return false;
  return /iPhone/i.test(navigator?.userAgent);
};

export const getLocalIp = async (): Promise<string> => {
  const res = await fetch('/health');
  const { ip } = await res.json();
  return ip;
};

export function getSelectOptionsFromStringEnum<T extends StringEnum>(
  enumSrc: T,
  sortedValues?: Array<T[keyof T]>,
): SelectOptionElement<T[keyof T]>[] {
  const selectOptions = objectEntries(enumSrc).map(([label, value]) => ({ label: label as string, value }));
  if (sortedValues) {
    return selectOptions.sort((a, b) => sortedValues.indexOf(a.value) - sortedValues.indexOf(b.value));
  }
  return selectOptions;
}
