import {
  Column,
  BadgeCell,
  BadgeCellComponentProps,
  BadgeColor,
  AvatarCellComponentProps,
  AvatarCell,
  AvatarSkeleton,
} from '@odin-labs/components';

import { getInitialsForUser } from 'utils';
import { JfsHistoricalJobsiteWorkerDocument } from 'containers/jobsiteFormSubmission/print/types';
import { getWorkerDocumentFieldValue } from 'containers/worker/utils';
import { DateTimeCell, JobsiteCell, TestTypeCell } from './cells';

export type TestResult = 'Pass' | 'No pass';
export const resultText: Record<TestResult, string> = {
  Pass: 'Pass',
  'No pass': 'Fail',
};
export const resultColor: Record<TestResult, BadgeColor> = {
  Pass: 'green',
  'No pass': 'red',
};

export type JobsiteWorkerDocumentColumn = Column<JfsHistoricalJobsiteWorkerDocument>;

export const getColumns = (): JobsiteWorkerDocumentColumn[] => [
  {
    Header: 'Jobsite',
    accessor: (document: JfsHistoricalJobsiteWorkerDocument): string =>
      document.jobsiteWorkerDocumentType?.jobsite.name,
    Cell: JobsiteCell,
  },
  {
    Header: 'Test Type',
    accessor: (document: JfsHistoricalJobsiteWorkerDocument): JfsHistoricalJobsiteWorkerDocument => document,
    Cell: TestTypeCell,
  },
  {
    Header: 'Date & Time',
    accessor: (document: JfsHistoricalJobsiteWorkerDocument): JfsHistoricalJobsiteWorkerDocument => document,
    Cell: DateTimeCell,
  },
  {
    Header: 'Result',
    Cell: BadgeCell,
    componentProps: (document: JfsHistoricalJobsiteWorkerDocument): BadgeCellComponentProps => {
      const { latestVersion } = document;
      const result: TestResult = getWorkerDocumentFieldValue(latestVersion, 'result');
      return {
        text: resultText[result],
        color: resultColor[result],
        withDot: true,
        background: 'transparent',
        size: 'lg',
      };
    },
  },
  {
    Header: 'Uploaded by',
    accessor: (document: JfsHistoricalJobsiteWorkerDocument): string => {
      const { latestVersion } = document;
      const { createdBy, updatedBy } = latestVersion.objectHistory ?? {};
      const { firstName, lastName } = (updatedBy ?? createdBy)?.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: AvatarSkeleton,
    componentProps: (document: JfsHistoricalJobsiteWorkerDocument): AvatarCellComponentProps => {
      const { latestVersion } = document;
      const { createdBy, updatedBy } = latestVersion.objectHistory ?? {};
      return {
        // src: document.reporter?.profilePicture?.downloadUrl,
        placeholder: getInitialsForUser(updatedBy ?? createdBy),
        // detail: getUserRole(document),
      };
    },
  },
];
