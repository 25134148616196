import React from 'react';
import cn from 'classnames';
import { MergeUnionType } from 'types';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { Avatar, Field, getFaIcon, OdinSelect } from '@odin-labs/components';
import { getInitialsForUser, getUserFullName } from 'utils';
import { getSelectOptions } from 'utils/constants';
import { EditableEnhancedWorker } from './types';

export type FormSubmissionEditableWorkerProps =
  | {
      jfsWorker: EditableEnhancedWorker;
      updateValue: (jfsWorker: EditableEnhancedWorker) => void;
    }
  | { loading: true };

const costCodeOptions = getSelectOptions({
  values: ['Cost Code 1', 'Cost Code 2', 'Cost Code 3'],
  labelGetter: null,
  keyGetter: null,
});

const ClockIcon = getFaIcon({
  icon: faClock,
  className: cn(
    'odin-text-xs sm:odin-text-base',
    'odin-absolute odin-ml-12 sm:odin-ml-15 odin-bottom-0',
    'odin-bg-odin-success odin-text-white odin-rounded-full odin-p-1',
  ),
});

export function FormSubmissionEditableWorker(props: FormSubmissionEditableWorkerProps): React.ReactElement {
  const { loading, jfsWorker, updateValue } = props as MergeUnionType<FormSubmissionEditableWorkerProps>;
  const { jobsiteWorker, extraData } = jfsWorker ?? {};
  const { isOnSite, contractorWorker } = jobsiteWorker ?? {};
  const { worker } = contractorWorker ?? {};
  const { workerId, user, profilePicture } = worker ?? {};
  const { downloadUrl: imageUrl } = profilePicture ?? {};
  const { workUnits, costCode, notes } = extraData ?? {};
  const userFullName = getUserFullName(user);

  return (
    <div
      className={`odin-gap-3 odin-grid odin-grid-cols-5 odin-p-5
      odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg`}
    >
      <div className="odin-flex odin-justify-center odin-items-center odin-row-span-3 sm:odin-row-span-2">
        <label htmlFor={`workUnits-${workerId}`}>
          <div className="odin-relative odin-flex odin-justify-center odin-h-full">
            <Avatar
              className="!odin-w-12 !odin-h-12 !odin-text-lg sm:!odin-w-20 sm:!odin-h-20 sm:!odin-text-3xl"
              size="3xl"
              src={imageUrl}
              placeholder={getInitialsForUser(user)}
              objectFit="cover"
            />
            {isOnSite && <ClockIcon />}
          </div>
          <span className="odin-block odin-text-base odin-font-medium odin-text-gray-900 odin-mt-3">
            {userFullName}
          </span>
        </label>
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <Field
          loading={loading}
          name={`workUnits-${workerId}`}
          label="Work Units"
          value={workUnits?.toString() ?? ''}
          type="number"
          onChange={(changedValue): void =>
            updateValue({
              ...jfsWorker,
              extraData: { ...jfsWorker.extraData, workUnits: Number(changedValue) || null },
            })
          }
        />
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <OdinSelect
          loading={loading}
          name={`costCode-${workerId}`}
          label="Cost Code"
          options={costCodeOptions}
          value={costCodeOptions.find((item) => item.value === costCode) ?? null}
          onChange={(changedValue): void =>
            updateValue({
              ...jfsWorker,
              extraData: { ...jfsWorker.extraData, costCode: changedValue?.value ?? null },
            })
          }
        />
      </div>
      <div className="odin-col-span-4 odin-pl-4 sm:odin-pl-0">
        <Field
          loading={loading}
          name={`notes-${workerId}`}
          label="Notes"
          value={notes ?? ''}
          onChange={(changedValue): void =>
            updateValue({
              ...jfsWorker,
              extraData: { ...jfsWorker.extraData, notes: changedValue },
            })
          }
        />
      </div>
    </div>
  );
}
