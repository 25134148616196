import React from 'react';
import cn from 'classnames';
import { JobsiteWorkerCard } from 'components/jobsiteWorkerCard/JobsiteWorkerCard';
import { JobsiteWorker, Worker } from 'containers/worker/types';
import { JfsWorkerWithHistoricalData } from 'containers/jobsiteFormSubmission/print/types';
import { ReportSectionTitle } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/components/reportSectionTitle';

export const classes = {
  container: cn('odin-flex odin-flex-col odin-space-y-6'),
};

export type WorkerReportJobsitesProps = {
  jfsWorker: JfsWorkerWithHistoricalData;
};

export function WorkerReportJobsites(props: WorkerReportJobsitesProps): React.ReactElement {
  const { jfsWorker } = props;
  const jobsiteWorkerAccesses = [jfsWorker.historicalData.jobsiteWorkerAccess].filter(Boolean);

  return (
    <div className={classes.container}>
      <ReportSectionTitle title="Jobsites" count={jobsiteWorkerAccesses.length} />
      {jobsiteWorkerAccesses?.map((jwa): React.ReactElement => {
        const { jobsiteContractor, contractorWorker, ...restJobsiteWorker } = jwa.jobsiteWorker;
        const { contractor } = contractorWorker;
        const jobsiteWorker: JobsiteWorker = {
          ...restJobsiteWorker,
          jobsiteContractor: {
            ...jobsiteContractor,
            contractor,
          },
          currentAccess: {
            isAllowed: jwa.isAllowedNow,
            notAllowedReason: jwa.notAllowedReason,
            endDate: jwa.endDate,
          },
          objectHistory: {} as never,
        };
        const worker: Worker = {
          ...contractorWorker.worker,
          objectHistory: {} as never,
          user: {} as never,
          editableFields: {} as never,
          workerComments: [] as never,
          jobsiteWorkers: [] as never,
        };

        return (
          <JobsiteWorkerCard
            worker={worker}
            jobsiteWorker={jobsiteWorker}
            key={`${jobsiteWorker?.jobsiteWorkerId}-worker-card`}
            disableActions
          />
        );
      })}
    </div>
  );
}
