import { MomentInput } from 'moment';
import { DocumentKey, JobsiteWorkerDocument, JobsiteWorkerDocumentVersion } from 'apollo/generated/client-operations';
import { getSelectOptions } from 'utils/constants';

export { DocumentKey };

export const byCreatedAtDescending = (
  docA: JobsiteWorkerDocumentVersion,
  docB: JobsiteWorkerDocumentVersion,
): number => {
  if (docA.objectHistory.createdAt > docB.objectHistory.createdAt) {
    return -1;
  }
  if (docB.objectHistory.createdAt > docA.objectHistory.createdAt) {
    return 1;
  }
  return 0;
};

export const getWorkerDocumentVersionLastUpdated = (
  jobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion,
): MomentInput => {
  const { createdAt, updatedAt } = jobsiteWorkerDocumentVersion?.objectHistory ?? {};
  return updatedAt ?? createdAt;
};

export const getMedicalDocType = (jobsiteDocument: JobsiteWorkerDocument): string => {
  switch (jobsiteDocument?.jobsiteWorkerDocumentType?.workerDocumentType?.key) {
    case DocumentKey.MedicalUrineDrugTest:
    case DocumentKey.PostEmploymentUrineDrugTest:
      return 'Urine Test';
    case DocumentKey.MedicalBreathAlcoholTest:
    case DocumentKey.PostEmploymentBreathAlcoholTest:
      return 'Breath Test';
    case DocumentKey.MedicalDrugTestingConsentForm:
      return 'Drug Testing Consent Form';
    case DocumentKey.MedicalCovid19OrientationAndProcedures:
      return 'COVID-19 Orientation and Procedures';
    default:
      return '';
  }
};

export const getLatestWorkerDocument = (jobsiteDocument: JobsiteWorkerDocument): JobsiteWorkerDocumentVersion => {
  const { jobsiteWorkerDocumentVersions } = jobsiteDocument ?? {};
  if (jobsiteWorkerDocumentVersions?.length) {
    const documentVersions = [...jobsiteWorkerDocumentVersions].sort(byCreatedAtDescending);
    return documentVersions[0];
  }
  return null;
};

export const getWorkerDocumentFieldValue = <T extends string>(
  documentVersion: Pick<JobsiteWorkerDocumentVersion, 'additionalFieldValues'>,
  field: string,
): T => {
  return documentVersion?.additionalFieldValues?.find(({ key }) => key === field)?.value as T;
};

export enum WorkerAccessRevokingCategory {
  MedicalDrugTest = 'Medical/Drug Test',
  DocumentationCredentialIssue = 'Documentation/Credential Issue',
  SafetyRisk = 'Safety Risk',
  Insubordination = 'Insubordination',
  DisorderlyConduct = 'Disorderly Conduct',
  SmokingDrinkingDrugUse = 'Smoking/Drinking/Drug Use',
  SecurityIssue = 'Security Issue',
  FightingViolence = 'Fighting/Violence',
  AccessViolation = 'Access Violation',
  Theft = 'Theft',
  Vandalism = 'Vandalism',
  Other = 'Other',
}

export const workerAccessRevokingCategoryOptions = getSelectOptions<WorkerAccessRevokingCategory>({
  values: Object.values(WorkerAccessRevokingCategory),
  labelGetter: null, // pass values as labels
});
