import React from 'react';
import { NewButton } from '@odin-labs/components';

import { getIcon } from 'utils/ui';

import { Form } from 'components/form';
import { FormSubmissionWorkerEditFormProps, FormSubmissionWorkerFormData } from './types';
import { getDefaultValues, getFormInputs, getJobsiteWorkerOptions } from './FormSubmissionWorkerEditForm.forms';

export function FormSubmissionWorkerEditForm(props: FormSubmissionWorkerEditFormProps): React.ReactElement {
  const { jfsWorkerEdit, onCancel, onConfirm } = props;
  const formRef = React.useRef<HTMLFormElement>(null);
  const isAddition = jfsWorkerEdit.editType === 'create';

  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit = (data: FormSubmissionWorkerFormData): void => {
    onConfirm(
      {
        id: jfsWorkerEdit.editType === 'create' ? new Date().toLocaleString() : jfsWorkerEdit.id,
        jobsiteWorker: jfsWorkerEdit.jobsitesInfo.find((j) => j.jobsiteWorkerId === data.jobsiteWorkerId.value)
          .jobsiteWorker,
        changeType: jfsWorkerEdit.changeType ?? (jfsWorkerEdit.editType === 'create' ? 'created' : 'updated'),
        extraData: { participantType: data.participantType.value },
        jobsitesInfo: jfsWorkerEdit.jobsitesInfo,
        associationType: jfsWorkerEdit.associationType,
      },
      jfsWorkerEdit.editType,
    );
  };

  const submitForm = React.useCallback((): void => {
    formRef.current.requestSubmit();
  }, [formRef.current]);

  const onIsDirtyChange = React.useCallback((isDirty): void => setIsFormDirty(isDirty), [setIsFormDirty]);

  const jobsiteOptions = getJobsiteWorkerOptions(jfsWorkerEdit);
  const formInputs = getFormInputs(jfsWorkerEdit, jobsiteOptions);
  const defaultValues = getDefaultValues(jfsWorkerEdit, jobsiteOptions);

  return (
    <div>
      <Form
        ref={formRef}
        inputs={formInputs}
        onIsDirtyChange={onIsDirtyChange}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      />
      <div className="odin-flex odin-justify-between odin-mt-6">
        <div>
          {isAddition && <NewButton icon={getIcon('fal fa-arrow-left')} text="Back" theme="naked" onClick={onCancel} />}
        </div>
        <div>
          <NewButton
            icon={getIcon(`fal ${isAddition ? 'fa-plus' : 'fa-check'}`)}
            text={isAddition ? 'Add Worker' : 'Update Worker'}
            onClick={submitForm}
            disabled={!isFormDirty}
          />
        </div>
      </div>
    </div>
  );
}
