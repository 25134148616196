import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Filter, NewButton, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  GetJobsiteFormSubmissionsOrderByField,
  useGenerateFormSubmissionsReportMutation,
  useGetJobsiteFormSubmissionsQuery,
} from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { evalJsCode, useQueryOrderBy, useResettableState } from 'utils';
import { getGraphQLError } from 'utils/error';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getJobsiteIdsForDevelopers } from 'utils/filters';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { useDependencies } from 'containers/jobsiteFormSubmission/utils';
import { RemoveFormSubmissionModal } from 'containers/forms/modals/RemoveFormSubmissionModal';
import { ArrowToBottomIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { FormsTabProps, JobsiteFormSubmission, JobsiteFormSubmissionFilters } from './types';
import { FormColumn, getColumns, getFilterItems, orderByFields } from './FormsTab.tables';

export function FormsTab(props: FormsTabProps): React.ReactElement {
  const { onTabApiChange, form } = props;

  const history = useHistory();

  const {
    value: jobsiteFormSubmissionToDelete,
    setValue: openRemoveFormSubmissionModal,
    resetValue: closeRemoveFormSubmissionModal,
  } = useResettableState<JobsiteFormSubmission>(null);

  const defaultStartDate = moment().startOf('day').subtract(1, 'month');
  const defaultEndDate = moment().startOf('day');

  const {
    page,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    startDate,
    endDate,
    developerIds,
    jobsiteIds,
    contractorIds,
    createdByIds,
    hasAttachments,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({
    updateJobsiteSelection: true,
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });
  const offset = page * limit;

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetJobsiteFormSubmissionsOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetJobsiteFormSubmissionsOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const {
    data: formSubmissionData,
    loading: formSubmissionsLoading,
    error: formSubmissionsError,
    refetch,
  } = useGetJobsiteFormSubmissionsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      jobsiteFormSubmissionsInput: {
        paginationInput: { limit, offset },
        formId: form.id,
        startDate: startDate.startOf('day').toDate(),
        endDate: endDate.endOf('day').toDate(),
        jobsiteIds,
        contractorIds,
        createdByIds,
        hasAttachments,
        orderBy,
      },
    },
    skip: isUrlLoading,
  });

  const { jobsites, loading: jobsitesLoading, error: jobsitesError } = useAvailableJobsites();

  const {
    edges,
    count: totalCount,
    filtersOptions,
  } = formSubmissionData?.getCurrentSession.user.jobsiteFormSubmissions ?? {};
  const jobsiteFormSubmissions = edges?.map(({ node }) => node);
  const pageCount = Math.ceil(totalCount / limit);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  const navigateToFormSubmissionEditPage = React.useCallback(
    (formSubmissionId: string): void => history.push(`/forms/${form.key}/${formSubmissionId}`),
    [history],
  );

  const navigateToFormSubmissionPrintPage = React.useCallback(
    (formSubmissionId: string): void => history.push(`/forms/${form.key}/${formSubmissionId}/print`),
    [history],
  );

  const { columns: columnsExpression } = form.content ?? {};
  const { dependencies: dependenciesExpressions } = form.content.edit ?? {};
  const dependencies = useDependencies(dependenciesExpressions);

  const columns = React.useMemo(() => {
    const extraColumns =
      (dependencies && columnsExpression && evalJsCode<FormColumn[]>(columnsExpression, { ctx: { dependencies } })) ??
      [];
    return getColumns({
      extraColumns,
      navigateToFormSubmissionPrintPage,
      navigateToFormSubmissionEditPage,
      openRemoveFormSubmissionModal,
    });
  }, [
    dependencies,
    columnsExpression,
    navigateToFormSubmissionPrintPage,
    navigateToFormSubmissionEditPage,
    openRemoveFormSubmissionModal,
  ]);

  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        startDate,
        endDate,
        jobsites,
        filtersOptions,
        developerIds,
        jobsiteIds,
        contractorIds,
        createdByIds,
        hasAttachments,
      }),
    [
      startDate,
      endDate,
      jobsites,
      filtersOptions,
      developerIds,
      jobsiteIds,
      contractorIds,
      createdByIds,
      hasAttachments,
    ],
  );

  const [generateFormSubmissionsReport, { loading: generateFormSubmissionsLoading }] =
    useGenerateFormSubmissionsReportMutation({
      onError: (apolloError: ApolloError) => {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(apolloError));
      },
      onCompleted: (response) => {
        window.open(response?.generateFormSubmissionsReport?.downloadUrl);
      },
    });

  const loading: boolean = formSubmissionsLoading || jobsitesLoading;
  const error: ApolloError = formSubmissionsError || jobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<JobsiteFormSubmission>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<JobsiteFormSubmissionFilters>): void => {
    const { dateRange: dateRangeFilter, ...restChangedFilters } = changedFilters;
    const dateRange = dateRangeFilter === null ? { startDate: null, endDate: null } : dateRangeFilter;
    updateUrl({
      page: null,
      ...dateRange,
      ...restChangedFilters,
      ...getJobsiteIdsForDevelopers(jobsites, changedFilters.developerIds),
    });
  };

  const onRowClickHandler = ({ data: jobsiteFormSubmission }: { data: JobsiteFormSubmission }): void => {
    navigateToFormSubmissionPrintPage(jobsiteFormSubmission.id);
  };

  return (
    <TableContainer>
      <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler}>
        <NewButton
          theme="white"
          size={['base', 'md:xs']}
          text="Download"
          hideTextOnMobile
          icon={ArrowToBottomIcon}
          onClick={(): void => {
            generateFormSubmissionsReport({
              variables: {
                input: {
                  formId: form.id,
                  startDate: startDate.startOf('day').toDate(),
                  endDate: endDate.endOf('day').toDate(),
                  jobsiteIds,
                  contractorIds,
                  createdByIds,
                  hasAttachments,
                  orderBy,
                },
              },
            });
          }}
          withSpinner={generateFormSubmissionsLoading}
        />
      </Filter>
      <Table
        loading={loading}
        columns={columns}
        data={jobsiteFormSubmissions}
        initialState={{ sortBy: tableSortBy, pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        onSortByChange={onSortByChangeHandler}
      />
      <RemoveFormSubmissionModal
        isOpen={!!jobsiteFormSubmissionToDelete}
        onCancel={closeRemoveFormSubmissionModal}
        onConfirm={closeRemoveFormSubmissionModal}
        form={form}
        jobsiteFormSubmission={jobsiteFormSubmissionToDelete}
      />
    </TableContainer>
  );
}
